export default [
  {
    category: 'int',
    type: 'int',
    author: 'Гордиенко_В',
    file: 'int/Гордиенко_В/supercompetition-v1-6636ui08.jpg',
    source: 'local',
  },
  {
    category: 'int',
    type: 'int',
    author: 'Ключка',
    file: 'int/Ключка/supercompetition-v1-9ipajwy1.jpg',
    source: 'local',
  },
  {
    category: 'int',
    type: 'int',
    author: 'Коваленко_И',
    file: 'int/Коваленко_И/supercompetition-v1-5rb9ax87.jpg',
    source: 'local',
  },
  {
    category: 'int',
    type: 'int',
    author: 'Кокот',
    file: 'int/Кокот/supercompetition-v1-tq8i16gg.jpg',
    source: 'local',
  },
  {
    category: 'int',
    type: 'int',
    author: 'Кофтунова',
    file: 'int/Кофтунова/supercompetition-v1-b9ced1n4.jpg',
    source: 'local',
  },
  {
    category: 'int',
    type: 'int',
    author: 'Луценко',
    file: 'int/Луценко/supercompetition-v1-9kjfxi6d.jpg',
    source: 'local',
  },
  {
    category: 'int',
    type: 'int',
    author: 'Лысак',
    file: 'int/Лысак/supercompetition-v1-hr9qcxhk.jpg',
    source: 'local',
  },
  {
    category: 'int',
    type: 'int',
    author: 'Лысянская Алена',
    file: 'int/Лысянская Алена/supercompetition-v1-z9cbmsrd.jpg',
    source: 'local',
  },
  {
    category: 'int',
    type: 'int',
    author: 'Семерей',
    file: 'int/Семерей/supercompetition-v1-y37ee73g.jpg',
    source: 'local',
  },
  {
    category: 'int',
    type: 'int',
    author: 'Семерей 2-0',
    file: 'int/Семерей 2-0/supercompetition-v1-3uwmwoin.jpg',
    source: 'local',
  },
]
