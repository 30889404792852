import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/quiz',
    name: 'Quiz',
    meta: { title: 'Survey' },
    component: () => import('../views/Quiz.vue'),
  },
  {
    path: '/finished',
    name: 'Finished',
    meta: { title: 'Survey complete' },
    component: () => import('../views/Finished.vue'),
  },
  {
    path: '/download',
    name: 'Download',
    meta: { title: 'Download results' },
    component: () => import('../views/Download.vue'),
  },
  {
    path: '*',
    name: 'Not Found',
    meta: { title: 'Page not found' },
    component: () => import('../views/NotFound.vue'),
  },
]

const router = new VueRouter({
  routes,
})

router.beforeEach((to, from, next) => {
  const seriesParts = [store.getters.subheading, store.state.surveySeries]
  const series = seriesParts.filter(Boolean).join(' ')

  const globalTitleParts = [store.getters.heading, series]
  const globalTitle = globalTitleParts.filter(Boolean).join(' | ')

  const titleParts = [to.meta?.title, globalTitle]
  const fullTitle = titleParts.filter(Boolean).join(' - ')

  document.title = fullTitle

  next()
})

export default router
