<template>
  <div class="flex flex-col min-h-screen">
    <div class="flex-1 flex items-center justify-center flex-col">
      <h1 class="text-6xl font-black leading-tight">
        {{ $store.getters.heading }}
      </h1>
      <p class="text-xl mb-10 mt-4">
        {{ $store.getters.subheading }}
        <span
          v-if="$store.state.surveySeries"
          class="inline-block rounded-md px-2 py-1 bg-gray-800 ml-1 text-base"
        >
          {{ $store.state.surveySeries }}
        </span>
      </p>
      <form
        @submit.prevent="onStartQuiz"
        autocomplete="off"
        class="flex flex-col max-w-full px-4 sm:flex-row sm:w-auto w-full"
      >
        <input
          id="name"
          type="text"
          ref="name"
          name="name"
          placeholder="Your full name"
          class="px-6 py-4 rounded-lg font-bold border-4 border-white appearance-none outline-none bg-transparent text-3xl my-4 focus:shadow-outline"
          v-model="localValue"
        />
        <button
          type="submit"
          class="bg-white text-gray-900 font-bold px-8 py-4 text-3xl rounded-md border-4 border-white sm:ml-4 my-4 focus:outline-none appearance-none focus:shadow-outline disabled:opacity-50 disabled:cursor-not-allowed"
          :disabled="!localValue"
        >
          Start
        </button>
      </form>
    </div>

    <div class="flex justify-center p-2">
      <router-link
        to="/download"
        class="text-gray-600 hover:text-white hover:underline py-1 px-2"
      >
        Download results
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      localValue: null,
    }
  },
  computed: {
    ...mapState(['voterName']),
    ...mapGetters(['quizInProgress', 'quizFinished']),
  },
  methods: {
    ...mapMutations(['updateVoterName']),
    onStartQuiz() {
      this.updateVoterName(this.localValue)
      this.$router.push({ path: 'quiz' })
    },
  },
  created() {
    this.localValue = this.voterName || null
  },
  mounted() {
    if (this.quizInProgress) {
      this.$router.push({ path: 'quiz' })
      return
    }
    if (this.quizFinished) {
      this.$router.push({ path: 'finished' })
      return
    }
    this.$refs.name.focus()
  },
}
</script>

<style lang="postcss">
#name::placeholder {
  @apply opacity-50;
}
</style>
