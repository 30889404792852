<template>
  <div id="app" class="text-center text-white bg-gray-900 min-h-screen">
    <router-view />
    <div class="absolute bottom-0 left-0 p-1">
      <span class="text-gray-600 text-xs py-1 px-2">
        Build {{ $store.getters.buildVersion }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  created() {
    const buildVersion = this.$store.getters.buildVersion
    const localVersion = this.$store.state.localVersion
    const keepProgress = this.$store.state.keepProgress
    if (!localVersion || (buildVersion !== localVersion && !keepProgress)) {
      this.$store.dispatch('startQuiz')
    }
  },
}
</script>

<style lang="postcss">
body {
  @apply bg-gray-900;
}
.router-link-exact-active {
  @apply text-green-600;
}
</style>
