import Vue from 'vue'
import Vuex from 'vuex'
import renders from './renders'
import axios from 'axios'
import router from '@/router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    subdomain: 'competition', //srez, exam, competition
    domain: 'cgischool.ua',
    customHeading: '', //empty sting to use default heading for subdomain
    customSubheading: '', //empty sting to use default subheading for subdomain, false to remove default subheading
    surveySeries: 'Mar 2024',
    versionMajor: 2,
    versionMinor: 1,
    optionsType: 'competency', //competency, like_dislike, 1_12, input
    shuffleEntries: true,
    keepProgress: false,

    defaultHeadings: {
      srez: 'S.R.E.Z',
      exam: 'EXAM',
      competition: 'Supercompetition',
    },
    defaultSubheadings: {
      srez: 'Spec Re-Evaluation Zero',
      exam: '',
      competition: '',
    },
    dataMapping: {
      author: 'author',
      type: 'type',
      src: 'file',
      category: 'category',
      source: 'source',
    },

    renders,
    entries: [],
    validSources: ['local', 'remote', 'google', 'iframe'],
    localVersion: localStorage.getItem('version'),
    quizFinished: false,
    currentEntryIndex: localStorage.getItem('currentEntryIndex') || 0,
    answers: JSON.parse(localStorage.getItem('answers')) || [],
    voterName: localStorage.getItem('voterName') || null,
    errors: [],
    remoteRegEx:
      '^(?:((?:https|http)://)|(?:/)).+(?:.jpg|jpeg|png|webp|mp4|mov|avi)$' /* eslint-disable-line */,
    iframeRegEx: '^(?:((?:https|http)://)|(?:/)).+' /* eslint-disable-line */,
    videoRegEx:
      '^(?:((?:https|http)://)|(?:/)).+(?:.mp4|mov|avi)$' /* eslint-disable-line */,
    imageRegEx:
      '^(?:((?:https|http)://)|(?:/)).+(?:.jpg|jpeg|png|webp)$' /* eslint-disable-line */,
    googleRegEx:
      '^https://drive.google.com/file/d/[a-zA-z0-9_]{33}/preview$' /* eslint-disable-line */,
    localRegEx: '^[^\\s]+\\.(jpg|jpeg|png|webp|mp4|mov|avi)$',
    categoryMapping: {
      anim: ['anim', 'animation'],
      mod: ['mod', 'modeling', 'modelling'],
      int: ['int', 'interior'],
      ext: ['ext', 'exterior'],
      mat: ['mat', 'material'],
    },
    downloadErrorMessage: '',
  },
  getters: {
    quizFinished: (state) => {
      return (
        state.currentEntryIndex &&
        state.entries.length - state.currentEntryIndex < 1
      )
    },
    quizInProgress: (state) => {
      return state.currentEntryIndex && state.voterName
    },
    siteUrl: (state) => {
      return [
        'https://',
        state.subdomain ? `${state.subdomain}.` : '',
        state.domain,
      ].join('')
    },
    tableName: (state) => {
      return `${state.subdomain}_entries`
    },
    heading: (state) => {
      return (
        state.customHeading ||
        state.defaultHeadings[state.subdomain] ||
        'Survey'
      )
    },
    subheading: (state) => {
      if (state.customSubheading === false) {
        return ''
      } else {
        return (
          state.customSubheading ||
          state.defaultSubheadings[state.subdomain] ||
          ''
        )
      }
    },
    appBuildTime: () => {
      return process.env.VUE_APP_BUILD_TIME
    },
    buildVersion: (state, getters) => {
      return `${state.versionMajor}.${state.versionMinor}.${
        getters.appBuildTime
      }${state.keepProgress ? 'k' : ''}`
    },
  },
  mutations: {
    updateVoterName(state, name) {
      state.voterName = name
      localStorage.setItem('voterName', name)
    },
    updateLocalVersion(state, version) {
      state.localVersion = version
      localStorage.setItem('version', version)
    },
    updateEntries(state, entries) {
      state.entries = entries
      localStorage.setItem('entries', JSON.stringify(state.entries))
    },
    pushAnswer(state, answer) {
      state.answers.push(answer)
      localStorage.setItem('answers', JSON.stringify(state.answers))
    },
    clearEntries(state) {
      state.entries = []
      state.currentEntryIndex = 0
      localStorage.removeItem('currentEntryIndex')
      localStorage.removeItem('entries')
    },
    clearRenders() {
      localStorage.removeItem('renders')
    },
    clearAnswers(state) {
      state.answers = []
      localStorage.removeItem('answers')
    },
    clearVoterName(state) {
      state.voterName = null
      localStorage.removeItem('voterName')
    },
    nextEntry(state) {
      const nextIndex = +state.currentEntryIndex + 1
      state.currentEntryIndex = nextIndex
      localStorage.setItem('currentEntryIndex', nextIndex)
    },
    addError(state, error) {
      if (error) {
        state.errors.push(error)
      }
    },
  },
  actions: {
    submitAnswers(context) {
      const api = '/submit.php'
      axios.post(api, {
        answers: context.state.answers,
        table: context.getters.tableName,
      })
    },
    startQuiz(context) {
      context.dispatch('resetQuiz')
      router.push('quiz')
    },
    resetQuiz(context) {
      context.commit('updateLocalVersion', context.getters.buildVersion)
      context.commit('clearEntries')
      context.commit('clearAnswers')
      context.commit('clearRenders')
      context.commit('clearVoterName')
    },
    parseEntries(context) {
      const localStorageEntries = JSON.parse(localStorage.getItem('entries'))
      if (localStorageEntries) {
        context.commit('updateEntries', localStorageEntries)
        return
      }
      const entries = context.state.renders.map((render) => {
        const category =
          Object.keys(context.state.categoryMapping).find((key) =>
            context.state.categoryMapping[key].includes(
              render[context.state.dataMapping.category].trim().toLowerCase(),
            ),
          ) || render[context.state.dataMapping.category]
        const source = render[context.state.dataMapping.source]
          ?.trim()
          .toLowerCase()
        const src = context.state.validSources.includes(source)
          ? {
              local: () => {
                return require(`../assets/renders/${
                  render[context.state.dataMapping.src]
                }`)
              },
              remote: () => {
                return render[context.state.dataMapping.src]
              },
              iframe: () => {
                return render[context.state.dataMapping.src]
              },
              google: () => {
                return `https://drive.google.com/file/d/${
                  render[context.state.dataMapping.src]
                }/preview`
              },
            }[source]()
          : render[context.state.dataMapping.src]

        return {
          type: render[context.state.dataMapping.type],
          author: render[context.state.dataMapping.author],
          source,
          category,
          src,
        }
      })

      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          ;[array[i], array[j]] = [array[j], array[i]]
        }
        return array
      }

      if (context.state.shuffleEntries) {
        context.commit('updateEntries', shuffleArray(entries))
      } else {
        context.commit('updateEntries', entries)
      }
    },
    downloadResult(context, payload) {
      axios
        .post('/download.php', {
          password: payload.password,
          table: context.getters.tableName,
        })
        .then((response) => {
          if (response.data.status === 'success') {
            context.state.downloadErrorMessage = ''
            window.location = response.data.filename
          }
          if (response.data.status === 'error') {
            context.state.downloadErrorMessage = response.data.message
          }
        })
    },
    collectErrors(context) {
      return context.state.entries.forEach((entry) => {
        let error
        if (!entry.src) {
          error = {
            message: 'Url is missing',
            entry,
          }
        } else if (!entry.source) {
          error = {
            message: 'Source type is missing',
            entry,
          }
        } else if (
          !Object.keys(context.state.categoryMapping).includes(entry.category)
        ) {
          error = {
            message: 'Invalid category',
            entry,
          }
        } else if (!context.state.validSources.includes(entry.source)) {
          error = {
            message: 'Invalid source type',
            entry,
          }
        } else if (
          entry.source === 'local' &&
          !new RegExp(context.state.localRegEx, 'i').test(entry.src)
        ) {
          error = {
            message: 'Invalid url for local source',
            entry,
          }
        } else if (
          entry.source === 'remote' &&
          !new RegExp(context.state.remoteRegEx, 'i').test(entry.src)
        ) {
          error = {
            message: 'Invalid url for remote source',
            entry,
          }
        } else if (
          entry.source === 'iframe' &&
          !new RegExp(context.state.iframeRegEx, 'i').test(entry.src)
        ) {
          error = {
            message: 'Invalid url for iframe source',
            entry,
          }
        } else if (
          ['local', 'remote'].includes(entry.source) &&
          entry.category === 'anim' &&
          !new RegExp(context.state.videoRegEx, 'i').test(entry.src)
        ) {
          error = {
            message: `Wrong file extension of ${entry.category} type entry`,
            entry,
          }
        } else if (
          ['local', 'remote'].includes(entry.source) &&
          entry.category !== 'anim' &&
          !new RegExp(context.state.imageRegEx, 'i').test(entry.src)
        ) {
          error = {
            message: `Wrong file extension of ${entry.category} type entry`,
            entry,
          }
        } else if (
          entry.source === 'google' &&
          !new RegExp(context.state.googleRegEx, 'i').test(entry.src)
        ) {
          error = {
            message: 'Invalid url for google source.',
            entry,
          }
        }
        context.commit('addError', error)
      })
    },
  },
  modules: {},
})
